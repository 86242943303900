<template>
<div>
    <v-container>
        <v-row>
            <v-col cols="12">
                <s-toolbar :color="'#244093'" dark label="Carga Masiva de Data" class="tran"></s-toolbar>
                <v-card>

                    <v-row justify="center" style="margin-top: 4px; margin-left: 10px; margin: auto">
                        <v-col cols="6" lg="4" md="4">
                            <s-select-definition :def="1138" v-model="TypeData" return-object label="Tipo"></s-select-definition>
                        </v-col>
                        <v-col cols="6" lg="6" md="6">
                            <s-text label="Nombre del Archivo" readonly v-model="txtNameArchive">
                                <template v-slot:prepend-inner>
                                    <b style="color: red">.csv</b>
                                    ></template>
                            </s-text>
                        </v-col>
                        <v-col cols="12" lg="2" md="2">
                            <v-btn rounded :color="'error'" small @click="chargeData()"><i style="font-size: 20px" class="fas fa-spinner"></i> Cargar Data</v-btn>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card class="mt-1">

                    <v-row style="margin-top: 4px; margin-left: 10px; margin: auto">
                        <v-col cols="12" lg="12" md="12">
                            <s-select-definition :def="1139" v-model="objPeriod" return-object label="Seleccione Periodo"></s-select-definition>
                        </v-col>
                        <v-col cols="6" lg="2" md="2">
                            <v-btn @click="migrated(1)" rounded :color="'success'" outlined small><i style="font-size: 20px" class="fas fa-spinner"></i> Migrar Cliente</v-btn>
                        </v-col>
                        <v-col cols="6" lg="2" md="2">
                            <v-btn @click="migrated(2)" rounded :color="'info'" outlined small><i style="font-size: 20px" class="fas fa-spinner"></i> Migrar Lotes</v-btn>
                        </v-col>
                        <v-col cols="12" lg="2" md="2">
                            <v-btn @click="migrated(3)" rounded :color="'warning'" outlined small><i style="font-size: 20px" class="fas fa-spinner"></i> Migrar cronograma</v-btn>
                        </v-col>
                    </v-row>
                </v-card>

                <!-- <v-col cols="12" lg="12" md="12">
                    <v-alert v-model="alert" style="border-radius: 5px 5px 5px 5px; overflow: auto; z-index: 9999999; width: 100%; right: 0px; top: 5%;" border="right" color="info" dark dense dismissible shaped close-icon="fas fa-times-circle">
                        <span style="font-family: Cambria">Archivo cargado correctamente</span>
                    </v-alert>
                </v-col> -->

            </v-col>
        </v-row>

        <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
            <v-card color="primary" dark>
                <v-card-text v-if="messageProcessing.length == 0">
                    Por favor espere
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text--white pt-4">
                        Error al cargar archivo <br />
                        {{ messageProcessing }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>

</div>
</template>

<script>
import _sGeneral from "@/services/HelperService.js";
import _sClcChargeMassive from '@/services/ReportClicks/ClcChargeMasive.js'
//import _loginEVOLTA from '@/services/General/GenLoginEVOLTA.js'

export default {
    props: {

    },
    components: {

    },
    data() {
        return {
            processing: false,
            messageProcessing: "",
            Attach1: null,
            txtNameArchive: null,
            TypeData: null,
            objPeriod: null
        };
    },

    watch: {
        TypeData() {
            this.txtNameArchive = this.TypeData.DedAbbreviation
        }
    },

    methods: {
       

        chargeData() {

            this.messageProcessing = ""

            if (this.txtNameArchive == null || this.txtNameArchive.length == 0) {
                this.$fun.alert("Ingrese Nombre de archivo", "warning")
                return;
            }

            this.$fun.alert("Seguro de comenzar con la carga ? ", "question").then(val => {
                if (val.value) {
                    this.processing = true,
                        _sClcChargeMassive.executeMassive({
                            TypeImport: this.TypeData.DedValue,
                            Route: this.txtNameArchive
                        }, this.$fun.getUserID()).then(resp => {
                                if (resp.status == 200) {
                                    this.processing = false
                                    alert("Migraciòn correcta. Cant. registros insertados ==> " + resp.data.Result)
                                    /* console.log("sssss", resp.data.Result) */
                                }
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = true;
                            })
                }
            })
        },

        migrated(xFlag) {

            var name = "";

            if (xFlag == 1) name = "Clientes"
            if (xFlag == 2) name = "Lotes"
            if (xFlag == 3) name = "Cronograma"

            var obj = {
                xFlag: xFlag,
                Period: this.objPeriod.DedAbbreviation
            }

          /*   console.log("params",obj) */

            this.messageProcessing = ""
            this.$fun.alert("¿Seguro de comenzar con la migración de: " + name + "?", "question").then(val => {
                if (val.value) {
                    this.processing = true,
                        _sClcChargeMassive.executeMigrated(obj, this.$fun.getUserID()).then(resp => {
                                if (resp.status == 200) {
                                    this.processing = false
                                    
                                    if(resp.data.xResult == 1){
                                        this.$fun.alert(name + ", migrados correctamente", "success")
                                    }

                                    if(resp.data.xResult == 0){
                                        this.$fun.alert("La operación no se completo correctamente, intentelo de nuevo por favor, de lo contrario comuniquese con el area de sistemas.", "warning")
                                    }
                                }
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = true;
                            })
                }
            })

        }

        /*  run() {

             if (this.Attach1 == null) {
                 this.$fun.alert("Falta adjuntar archivo", "warning");
                 return;
             }

             if (this.Attach1)
                 var formData = new FormData();
             formData.append(1, this.Attach1);

             console.log(this.Attach1)

             _sGeneral.uploadfile("123", this.$fun.getUserID(), formData, 3).then(resp => {
                     if (resp.status == 200) {
                         this.$fun.alert("Archivo cargado correctamente", "success");
                         console.log(resp)
                     }
                 },
                 (e) => {
                     alert(e.response.data.Message)
                 })
         }, */

    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
